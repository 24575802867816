<template>
  <div v-click-outside="clickOutside" :class="['header-search', { 'focus': form.focus, 'expanded': expanded }]">
    <div class="search-form" @focusin.capture="toggleFocus" @focusout.capture="toggleFocus">
      <div class="search-form-input">
        <b-form-input
          class="search-input"
          type="text"
          :placeholder="$t(`${tPath}.placeholder`)"
          v-model="form.value"
          :debounce="form.debounce"
          ref="searchInput"
          aria-autocomplete="list"
          autocomplete="false"
          @keydown.enter="submit"
          @keydown.esc="reset"
        />
      </div>
      <slot />
    </div>

    <div class="search-results">
      <hr class="mt-3"/>
      <div class="results-body" :style="!$root.isDesktop ? { top: `${$root.header.inViewportHeight}px` } : null">
        <div class="container">
          <b-row>
            <b-col v-for="(row, rIndex) in resultRows" :key="rIndex" :[$root.md.key]="6">
              <div v-for="(resultType, tKey) in row" :key="tKey" :class="['resulttype', `resulttype-${tKey}`]">
                <div :id="`HeaderSearch_ResultType_${tKey}`" class="resulttype-title">{{ $t(`${tPath}.results.${tKey.toLowerCase()}.title`) }}</div>

                <div v-if="resultType.items.length <= 0" class="resulttype-noresults">{{ $t(`${tPath}.results.${tKey.toLowerCase()}.noresults`, { query: form.value }) }}</div>

                <template v-else>
                  <ul class="resulttype-itemlist" :aria-describedby="`HeaderSearch_ResultType_${tKey}`">
                    <li v-for="(item, iIndex) in resultType.items" :key="iIndex">
                      <a :href="$store.getters['gui/getBaseUrl'](resultType.urlType, item.urlSlug)" class="resulttype-item" >
                        <template v-if="resultTypesWithImages.includes(resultType.urlType)">
                          <img v-if="item.image" :src="item.image"/>
                           <c-placeholder-image v-if="!item.image" ratio="4x3" size="75"/>
                        </template>
                        <span v-if="item.additional">{{item.additional.toUpperCase()}}</span>
                        <strong>{{ item.title }}</strong>
                      </a>
                    </li>
                  </ul>

                  <div v-if="resultType.urlType === 'product' && showShowMoreProducts" ref="showmoreproducts">
                    <slot name="showmoreproducts" v-bind="{ queryString: searchQuery }"/>
                  </div>
                  <div v-else-if="resultType.urlType === 'marketingproduct' && showShowMoreMarketingProducts" ref="showmoremarketingproducts">
                    <slot name="showmoremarketingproducts" v-bind="{ queryString: searchQuery }"/>
                  </div>
                  <div v-else-if="resultType.row === 'content' && showShowMoreContent" ref="showmorecontent">
                    <slot name="showmorecontent" v-bind="{ queryString: searchQuery }"/>
                  </div>
                  <div v-else-if="resultType.urlType === 'education' && showShowMoreEvent" ref="showmoreeducation">
                    <slot name="showmoreeducation" v-bind="{ queryString: searchQuery }"/>
                  </div>
                </template>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeaderSearch',
  data () {
    return {
      form: {
        value: '',
        debounce: 200,
        focus: false
      },
      resultTypesWithImages: ['product', 'marketingproduct'],
      resultsOnlyLoggedIn: ['MarketingProducts']
    }
  },
  props: {
    searchoptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    expanded () {
      return this.form.value !== ''
    },
    contents () {
      return this.$store.getters['globalsearch/getContents']
    },
    products () {
      return this.$store.getters['globalsearch/getProducts']
    },
    showShowMoreProducts () {
      return this.products.Products.hasMore
    },
    showShowMoreMarketingProducts () {
      return this.products.MarketingProducts.hasMore
    },
    showShowMoreContent () {
      return this.contents.WebsiteContents.hasMore
    },
    showShowMoreEvent () {
      return this.products.Events.hasMore
    },
    resultRows () {
      const results = [].concat(this.contents, this.products)
      if (!this.searchoptions.isLoggedIn) {
        this.resultsOnlyLoggedIn.forEach(x => {
          results.forEach(r => {
            if (x in r) {
              delete r[x]
            }
          })
        })
      }
      return results
    },
    searchQuery () {
      return this.form.value
    }
  },
  methods: {
    toggleFocus () {
      this.form.focus = !this.form.focus
    },
    reset () {
      this.form.value = ''
      this.$store.commit('globalsearch/reset')
    },
    search () {
      if (this.form.value.length <= 0) {
        this.reset()
      } else {
        this.$store.dispatch('globalsearch/searchByQuery', { query: this.form.value })
      }
    },
    submit (e) {
      if (this.form.value.length <= 0) {
        this.reset()
      } else {
        console.log(this.searchoptions)
        if (this.searchoptions.defaultSearchPage) {
          window.location = `${this.searchoptions.defaultSearchPage}?${this.searchoptions.parameterName}=${this.form.value}`
        } else {
          const submitLinkProducts = this.$refs.showmoreproducts[0].querySelector('a')
          if (submitLinkProducts) submitLinkProducts.dispatchEvent(new MouseEvent('click', { ctrlKey: e.ctrlKey, shiftKey: e.shiftKey, altKey: e.altKey }))
          const submitLinkMarketingProducts = this.$refs.showmoremarketingproducts[0].querySelector('a')
          if (submitLinkMarketingProducts) submitLinkMarketingProducts.dispatchEvent(new MouseEvent('click', { ctrlKey: e.ctrlKey, shiftKey: e.shiftKey, altKey: e.altKey }))
          const submitLinkContent = this.$refs.showmorecontent[0].querySelector('a')
          if (submitLinkContent) submitLinkContent.dispatchEvent(new MouseEvent('click', { ctrlKey: e.ctrlKey, shiftKey: e.shiftKey, altKey: e.altKey }))
        }
      }
    },
    clickOutside () {
      this.$emit('close-search')
    }
  },
  mounted () {
    this.$refs.searchInput.$el.focus()
  },
  watch: {
    expanded: {
      immediate: true,
      handler () {
        this.$root.$emit('scrollbar:toggle', { key: this.$options.name, state: this.expanded, from: 0, till: this.$root.md.value })
      }
    },
    'form.value': {
      immediate: true,
      handler () {
        this.search()
      }
    }
  }
}
</script>

<style lang="scss">
// mobile
$headersearch-form-gap: $spacer * 0.5 !default;
$headersearch-form-height: $spacer * 2 + $headersearch-form-gap * 2 !default;
$headersearch-form-border: map-get($borders, 'bold') !default;
$headersearch-form-zindex: $zindex-sticky + 11 !default;
$headersearch-form-focus-border: set-nth($headersearch-form-border, 3, $primary) !default;

$headersearch-form-submit-padding-y: 0 !default;
$headersearch-form-submit-padding-x: $headersearch-form-height * 0.33 !default;
$headersearch-form-focus-submit-color: $primary !default;

$headersearch-form-input-padding-y: $headersearch-form-submit-padding-y !default;
$headersearch-form-input-padding-x: $headersearch-form-submit-padding-x !default;

$headersearch-results-zindex: $zindex-sticky + 10 !default;

$headersearch-results-body-resulttype-border: map-get($borders, 'base') !default;

$headersearch-results-body-resulttype-title-gap: $spacer * 0.3 !default;

$headersearch-results-body-resulttype-itemlist-item-gutter: 10px !default;
$headersearch-results-body-resulttype-itemlist-item-padding-y: $spacer * 0.4 !default;
$headersearch-results-body-resulttype-itemlist-item-padding-x: $headersearch-results-body-resulttype-itemlist-item-padding-y !default;

$headersearch-results-body-resulttype-itemlist-item-hover-img-fallback-color: darken($gray-300 , 10%) !default;

// desktop
$headersearch-desktop-form-gap: $spacer * 0.5 !default;

$headersearch-desktop-results-y: $spacer * -0.75 !default;
$headersearch-desktop-results-box-shadow: 0px 8px 18px rgba($black, 0.05) !default;

$headersearch-desktop-results-header-padding-x: $grid-gutter-width * 0.5 !default;
$headersearch-desktop-results-header-border: map-get($borders, 'base') !default;

$headersearch-desktop-results-body-max-height: 70vh !default;

.header-search {
  position: relative;
  flex-grow:1;
  background-color: $white;

  .search-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 0 $headersearch-form-gap 0;
    height: $headersearch-form-height;
    background-color:  $input-bg;
    z-index: $headersearch-form-zindex;

    &-input{
      border: $headersearch-form-border;
      border-radius: $headersearch-form-height;
      height: 100%;
      width: 100%;
    }

    .search-toggle{
      padding-right: 0;
    }

    .search-input {
      flex: 1 1 100%;
      margin: 0;
      padding: $headersearch-form-input-padding-y $headersearch-form-input-padding-x;
      height: 100%;
      background: none;
      border: 0 none;
      outline: 0 none;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      font-style: inherit;
      box-shadow: none;

      &::placeholder {
        color: $body-color;
      }
    }

    .search-submit {
      flex: 0 0 auto;
      padding: $headersearch-form-submit-padding-y $headersearch-form-submit-padding-x;
      background: none;
      border: 0 none;

      .clear{
        cursor: pointer;
      }

      &:last-child {
        padding-left: 0;
      }
    }
  }

  .search-results {
    display: none;
    z-index: $headersearch-results-zindex;

    hr{
      border-style: solid;
    }

    .results-header {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;

      .close {
        color: inherit;

        &:hover {
          color: $link-hover-color;
        }
      }
    }

    .results-body {
      max-height:calc(100vh - 88px) ;
      overflow-y:auto;
      background-color: $white;
      z-index: inherit;

      .resulttype {
        padding: $spacer 0;
        border-bottom: $headersearch-results-body-resulttype-border;

        .resulttype-title {
          margin-bottom: $headersearch-results-body-resulttype-title-gap;
          font-size: $font-size-sm;
          color: $dark;
        }

        .resulttype-noresults {
          font-weight: $font-weight-bold;
        }

        .resulttype-itemlist {
          @include list-unstyled();

          .resulttype-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0 ($headersearch-results-body-resulttype-itemlist-item-padding-x * -1);
            padding: $headersearch-results-body-resulttype-itemlist-item-padding-y $headersearch-results-body-resulttype-itemlist-item-padding-x;
            border-radius: $border-radius;
            color: inherit;
            text-decoration: none;

            > * {
              display: block;
              margin: 0;
              padding-left: $headersearch-results-body-resulttype-itemlist-item-gutter * 0.5;
              padding-right: $headersearch-results-body-resulttype-itemlist-item-gutter * 0.5;

              &:first-child { padding-left: 0; }
              &:last-child { padding-right: 0; }
            }

            > img,
            > .placeholder-image {
              max-width: 75px;
              width: 100%;
              .bi {

                height: 100%;
                color: $gray-300;
              }
            }

            &:hover {
              background-color: $gray-300;

              > .is-fallback-image {
                .bi {
                  color: $headersearch-results-body-resulttype-itemlist-item-hover-img-fallback-color;
                }
              }
            }
          }
        }

      }
    }
  }

  &.focus {
    .search-form {
      &-input{
        border: $headersearch-form-focus-border;
      }

      .search-submit {
        color: $headersearch-form-focus-submit-color;
      }
    }
  }

  &.expanded {
    border-bottom-right-radius: 1.75rem;

    .search-form {
      margin-right: 0;
    }

    .search-results {
      display: block;
    }
  }

  @include media-breakpoint-up($desktop-breakpoint) {
    .search-form {
      padding: $headersearch-desktop-form-gap 0;
      margin: 0;

      .search-toggle{
        padding-right: 1.25rem;
      }
    }

    .search-results {
      display: none;
      background-color: $white;
      border-radius: $border-radius;
      box-shadow: $headersearch-desktop-results-box-shadow;

      .results-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: static;
        padding: 0 $headersearch-desktop-results-header-padding-x;
        height: calc(#{$headersearch-form-height} + (#{$headersearch-desktop-results-y} * -2));
        border-bottom: $headersearch-desktop-results-header-border;
      }

      .results-body {
        position: static;
        max-height: $headersearch-desktop-results-body-max-height;
        background: none;

        .resulttype {
          &:last-child {
            border-bottom: 0 none;
          }
        }
      }
    }

    &.expanded {
      .search-backdrop {
        display: block;
      }

      .search-form {
        margin-right: 0;
      }

      .search-results {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
